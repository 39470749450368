<template>
  <div>
    <div class="mb-8 flex justify-between items-center">
      <div class="text-lg font-bold">Admins</div>
      <button class="btn btn-blue btn-sm" @click="$refs.addAdminModal.open()">
        + Add New Admin
      </button>
    </div>

    <datatable
      :data="admins"
      :columns="adminColumns"
      :actions="actions"
      :fillable="false"
      ref="table"
    />

    <modal className="w-11/12 md:w-443px lg:w-443px" ref="addAdminModal">
      <h1 class="text-2xl text-left font-bold mb-8">
        Add Merchant Admin
      </h1>
      <form @submit.prevent="submit">
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span>{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          leftIcon="mail-outline"
          name="first_name"
          :form="form"
          v-model="form.data.first_name.value"
        >
          First Name
        </form-group>

        <form-group
          leftIcon="mail-outline"
          name="last_name"
          :form="form"
          v-model="form.data.last_name.value"
        >
          Last Name
        </form-group>

        <div class="mt-3">
          <form-group
            leftIcon="mail-outline"
            name="email"
            :form="form"
            v-model="form.data.email.value"
          >
            Email
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            leftIcon="mail-outline"
            name="phone"
            :form="form"
            v-model="form.data.phone.value"
          >
            Phone Number
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="select"
            :options="roleOptions"
            leftIcon="business-outline"
            name="merchant_role"
            :form="form"
            v-model="form.data.merchant_role.value"
          >
            Role
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="select"
            :options="regionOptions"
            leftIcon="business-outline"
            name="region"
            :form="form"
            v-model="form.data.region.value"
          >
            Region
          </form-group>
        </div>

        <div class="mt-3">
          <form-group
            type="select"
            :options="branchOptions"
            leftIcon="business-outline"
            name="branch"
            :form="form"
            v-model="form.data.branch.value"
          >
            Branch
          </form-group>
        </div>

        <div class="flex justify-center">
          <button
            class="btn btn-blue font-normal py-4 px-16"
            type="submit"
            :disabled="form.loading"
          >
            Submit
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
<script>
export default {
  props: {
    profile: {
      required: true,
      type: [Array, Object]
    }
  },
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'merchant_id', value: this.profile?.id },
        { name: 'first_name', rules: 'required|aplpha' },
        { name: 'last_name', rules: 'required|aplpha' },
        { name: 'email', rules: 'required|email' },
        { name: 'phone', rules: 'required|phone' },
        { name: 'merchant_role', rules: 'required|aplpha' },
        { name: 'branch', rules: 'required' },
        { name: 'region', rules: 'required' }
      ]),
      adminColumns: [
        {
          name: 'first_name',
          th: 'Fullname',
          render: admin => `${admin?.first_name} ${admin?.last_name}`
        },
        {
          name: 'phone',
          th: 'Phone Number'
        },
        {
          name: 'descriptions',
          th: 'Role',
          render: admin =>
            this.$options.filters.fromSlug(admin?.descriptions?.merchant_role)
        },
        {
          name: 'region',
          th: 'Region',
          render: admin => admin?.descriptions?.region
        },
        {
          name: 'branch',
          th: 'Branch',
          render: admin => admin?.descriptions?.branch
        },
        {
          name: 'created_at',
          th: 'Created At',
          render: (admin, created_at) =>
            this.$options.filters.dateFormat(created_at, 'D, dd M Y')
        }
      ],
      actions: [
        {
          text: 'delete',
          class: 'border-red-500 text-red-500 rounded-sm px-4 py-2',
          action: this.deleteAdmin
        }
      ]
    };
  },
  computed: {
    admins() {
      return this.profile?.admins || [];
    },
    regionOptions() {
      return this.profile?.regions.length
        ? this.profile?.regions?.map(region => ({
            title: region.name,
            value: region.name
          }))
        : [];
    },
    branchOptions() {
      return this.profile?.branches.length
        ? this.profile?.branches?.map(region => ({
            title: region.name,
            value: region.name
          }))
        : [];
    },
    roleOptions() {
      return ['super_admin', 'branch_admin', 'finance_admin', 'agent']?.map(
        role => ({
          title: this.$options.filters.fromSlug(role),
          value: role
        })
      );
    }
  },
  methods: {
    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }
      this.form.loading = true;
      this.form.error = false;

      await this.$post({
        url: `${this.$baseurl}/admin/merchants/add-admin`,
        headers: this.headers,
        data: this.getFormData(),
        success: () => {
          this.resetForm();
          this.$refs.addAdminModal.close();
          this.$emit('success');
        },
        error: error => {
          this.form.error = error;
        }
      });

      this.form.loading = false;
    },
    async deleteAdmin({ id }) {
      await this.$_delete({
        url: `${this.$baseurl}/admin/merchants/${id}/delete`,
        headers: this.headers,
        success: () => {
          this.$emit('success');
        },
        error: error => {
          console.log(error);
        }
      });
    }
  }
};
</script>
